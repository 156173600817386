import React from 'react';
import Link from 'gatsby-link';
import dayjs from 'dayjs';
import lodash from 'lodash';
import styled from 'styled-components';
import className from 'classnames';

import constant from '../constant';
import Table from './table';
import { resetToken } from '../http';
import { getLink } from '../utils';

const KeyContainer = styled.div`
  .buy-button {
    height: 30px;
    width: 112px;
    line-height: 30px;
    font-size: 14px;
    border-radius: 15px;
    background: #F6F9FF;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
    &::before {
      display: inline-block;
      content: ' ';
      margin-right: 10px;
      vertical-align: top;
      width: 35px;
      height: 30px;
      line-height: 30px;
      background: ${constant.active} center / contain url(${constant.cart});
    }
  }
  .order-buy {
    .buy-button {
      &.active {
        color: white;
        background: #66BB6A;
        pointer-events: none;        
        ::before {
          background-color: #66BB6A;
          background-image: url(${constant.select});
        }
      }
      &.gray {
        filter: grayscale(100%);
        pointer-events: none;
      }
    }
  }
  .renew {
    display: inline-block;
    color: #CFD3DA;
    filter: grayscale(100%);
    pointer-events: none;
    &.active {
      color: #3B416D;
      filter: none;
      pointer-events: unset;
    }
    &::before {
      margin-right: 24px;
    }
  }
  .token {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  .reset-token {
    vertical-align: 0;
  }
`;
const levelData = {
  DOTA2: [true, true, true, true],
  LOL: [true, true, true, false],
  CSGO: [true, true, true, true],
  AOV: [true, false, false, false]
}
class KeyStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // product: [0, 0, 0],
      token: props.key_data.gateway_auth_key
    }
    const { view_set } = props;
    this.columns = this.genKeyColumns(view_set);
  }
  // selectItem(idx, id) {
  //   const product = [ ...this.state.product ];
  //   product[idx] = id;
  //   this.setState({ product });
  // }
  genKeyColumns(keyStatus) {
    const { title } = keyStatus;
    const { provideData } = this.props;
    const game = [null, 'DOTA2', 'LOL', 'CSGO', 'AOV'];

    const columns = [
      {
        text: title[0],
        key: 'member_product_type_id',
        render(data) {
          // return game[data];
          return provideData[data - 1].title;
        }
      },
      {
        text: title[1],
        key: 'level',
        render(data) {
          return <span style={{ color: '#66BB6A' }}>{keyStatus.serve_degree[data]}</span>
        }
      },
      // {
      //   text: title[2],
      //   key: ''
      // },
      {
        text: title[3],
        key: 'expire',
        render(data) {
          if (data === 0) return keyStatus.expire;
          return dayjs.unix(data).format('YYYY-MM-DD HH:ss');
        }
      },
      {
        text: title[4],
        key: 'rate_limit',
        render(data) {
          if (!data) return '';
          const { use, count } = data;
          return (
            <div>
              <div>{use} / {count}</div>
              <div><span className="badge badge-info">{keyStatus.status[0]}</span> / <span className="badge badge-success">{keyStatus.status[1]}</span></div>
            </div>
          );
        }
      },
      {
        text: title[5],
        key: '',
        render: (_, data) => {
          const { member_product_type_id, level } = data;
          const base = Number(member_product_type_id) - 1;
          const productId = base * 5 + level;
          return (
            <Link
              className={className("renew buy-button shadow-sm", { active: level !== 1 })}
              to={getLink('/price/order')}
              state={{
                id: [productId],
                type: 'renew'
              }}
            >
              {keyStatus.renew}
            </Link>
          );
        }
      },
      {
        text: title[6],
        key: '',
        render: (_, data) => {
          if (!data) return '';
          const {
            member_product_type_id,
            level,
          } = data;
          const base = Number(member_product_type_id) - 1;
          const levelArr = lodash.get(levelData, `${game[Number(member_product_type_id)]}`, [])
          // const { product } = this.state;
          // const pIdx = Number(member_product_type_id) - 1;
          // const curItem = product[pIdx];
          // const base = (Number(member_product_type_id) - 1) * 4 + 2; // 每一个产品有4种， 只把收费的放在这里
          return (
            <div className="order-buy d-flex justify-content-between p-3">
              {keyStatus.type_list.slice(1).map((it, idx) => (
                <Link
                  className={className(
                    "buy-button shadow-sm",
                    { gray: idx < (level - 2) || !levelArr[idx] },
                    { active: (level - 2) === idx }
                  )}
                  to={getLink('/price/order')}
                  state={{
                    id: [Number(base * 5 + 2 + idx)],
                    type: 'renew'
                  }}
                // onClick={() => this.selectItem(pIdx, base + idx)}
                >
                  {it}
                </Link>
              ))}
            </div>
          )
        }
      }
    ];
    return columns;
  }
  resetToken() {
    resetToken().then((res) => {
      if (res.status === 0) {
        this.setState({ token: res.data });
        this.props.alert('重置成功', 'success');
      } else {
        this.props.alert(res.msg, 'danger');
      }
    }).catch(({ data }) => this.props.alert(data.msg, 'danger'))
  }
  render() {
    const { key_data, view_set } = this.props;
    const { token } = this.state;
    return (
      <KeyContainer>
        <div className="form-group">
          <label>Token:</label>
          <input readonly="" type="text" value={token} className="mr-5" />
          <button
            className="reset-btn btn btn-primary "
            onClick={() => this.resetToken()}
          >{view_set.reset_token}
          </button>
        </div>
        <Table className="table shadow-sm" cols={this.columns} data={key_data.member || []} />
      </KeyContainer>
    );
  }
}

export default KeyStatus;