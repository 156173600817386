import React from 'react';

export default function Table({ cols, data, className }) {
  return (
    <table className={className}>
      <Table.Head cols={cols} />
      <tbody>
        {data.map((it, idx) => <Table.Row cols={cols} data={it} key={idx} />)}
      </tbody>
    </table>);
}

Table.Head = function({ cols }) {
  return (
    <thead>
      <tr>{cols.map(col => (
        <th key={col.text} className="text-center" style={col.thStyle}>{
          col.thRender ?
            col.thRender(col.text) :
            col.text
        }</th>)
      )}</tr>
    </thead>
  );
};


Table.Row = function({ cols, data, ...rest }) {
  return (
    <tr {...rest}>
      {cols.map((col, idx) => (
        <td key={idx} style={col.style}>
          {col.render ? col.render(data[col.key], data) : data[col.key]}
        </td>
      ))}
    </tr>);
};