import React from 'react';
import className from 'classnames';

function Pagination({ total, req, size }) {
  const [cur, selectCur] = React.useState(1);
  const arr = [];
  const pages = Math.ceil(total / size);
  const paging = (p) => {
    if (p === 0 || (p === arr.length + 1)) return cur;
    req(p, size);
    selectCur(p);
  }
  for (let i = 1; i <= pages; i += 1) {
    arr.push(i);
  }
  return (
    <nav aria-label="Page navigation example" className="d-flex flex-row-reverse" style={{ width: 1180 }}>
      <ul className="pagination">      
        <li
          className="page-item pointer"
          onClick={() => paging(cur - 1)}
        >
          <span
            className="page-link"
          >&lt;</span>
        </li>
        {arr.map(page => (
          <li
            className={className("page-item pointer", { active: page === cur })}
            onClick={() => paging(page)}
            key={page}
          >
            <span
              className="page-link"
            >{page}
            </span>
          </li>
        ))}
        <li
          className="page-item pointer"
          onClick={() => paging(cur + 1)}
        ><span className="page-link">&gt;</span>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;