import React from 'react';
import styled from "styled-components";
import className from "classnames";
import dayjs from "dayjs";
import Link from 'gatsby-link';
import _ from 'lodash';

import Alert from '../../components/alert';
import constant from '../../constant';
import Layout from "../../components/layout";
import {
  setToken,
  getUserInfo,
  getOrderList,
  modifyUser,
  getKeyInfo,
} from '../../http';

import SEO from "../../components/seo";
import withI18next from '../../components/withI18next';
import Table from '../../components/table';
import Spinner from '../../components/spinner';
import Pagination from '../../components/pagination';
import KeyStatus from '../../components/keyStatus';
import { getLink } from '../../utils';
import LoadingWrapper from './../../components/loadingWrapper';

const UHeader = styled.div.attrs({ id: 'product-header' })`
  height: 279px;
  margin: 0 auto;
  margin-top: -80px;
  max-width: 1920px;
  background: linear-gradient(to bottom, #708BFF, #8BA1FF);
  canvas {
    position: absolute;
    height: 279px;
    width: 100%;
  }
`;

const UContent = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  min-height: 800px;
  position: relative;
  .title-header {
    position: absolute;
    top: -36px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 36px;
    line-height: 36px;
    max-width: 1180px;
    color: white;
    .title-item {
      width: 113px;
      font-size: 14px;
      border-radius: 3px 3px 0 0;
      cursor: pointer;
      &.active {
        color: ${constant.subTitle};
        background: white;
      }
    }
  }
  .u-container {
    padding-top: 50px;
    margin: 0 auto;
    position: relative;
    min-height: 500px;
    max-width: 1180px;
    overflow-x: auto;
    .table {
      font-size: 14px;
      /* @media (max-width: 750px) {
        width: 450px;
      } */
      min-width: 1180px;
      td {
        text-align: center;
        vertical-align: middle;
        .status-1 {
          color: #E24C4C;
        }
        .status-2 {
          color: #66BB6A;          
        }
        .status-3 {
          color: #ffc107;
        }
      }
    }
    th {
      height: 50px;
      padding: 0;
      line-height: 50px;
      border: none;
      background: #E8ECFF;
    }
    .pay-button {
      display: inline-block;
      height: 30px;
      line-height: 30px !important;
      width: 112px;
      font-size: 14px;
      border-radius: 15px;
      background: #F6F9FF;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
    }
  }
  .user-info {
    max-width: 550px;
    h3 {
      padding-left: 10px;
      margin-bottom: 25px;
      margin-top: 20px;
      font-size: 24px;
      font-weight: 600;
    }
    .form-group {
      margin: 0;
      /* margin-bottom: 20px; */
      /* line-height: 44px; */
      padding: 10px;
      label {
        width: 130px;
        margin-right: 15px;
        font-size: 14px;
        @media (max-width: 750px) {
          width: auto;
        }
      }
      input {
        width: 348px;
        height: 44px;
        text-indent: 20px;
        border: 1px solid #dde1e6;
        border-radius:22px;
        font-size: 16px;
        @media (max-width: 750px) {
          display: block;
        }
      }
    }
    .info-button {
      .btn {
        margin-right: 20px;
        border-radius: 24px;
        width: 100px;
        border: none;
        background: ${constant.active};
      }
    }
  }
  .key-info {
    .form-group {
      margin: 0;
      margin-bottom: 20px;
      line-height: 44px;
      label {
        width: 56px;
        margin-right: 15px;
        font-size: 14px;
      }
      input {
        width: 857px;
        height: 44px;
        text-indent: 20px;
        border: 1px solid #dde1e6;
        border-radius:22px;
        font-size: 16px;
      }
    }
  }
  .hide {
    display: none;
  }
`

const product_id_map = new Map([ // key-product_id value- 位置
  [1, 1], [2, 2], [3, 3], [4, 4], [5, 6],
  [6, 7], [7, 8], [8, 9], [9, 11], [10, 12],
  [11, 13], [12, 14], [13, 16], [14, 17], [15, 18],
  [16, 19], [17, 20], [18, 10], [19, 5], [20, 15]
])

class UserCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeInfo: {
        email: '',
        company: '',
      },
      alert: '',
      dict: 0,
      email: '',
      company: '',
      old_password: '',
      new_password: '',
      repeat_password: '',
      order_list: '',
      key_data: '',
      loading: false,
    };
    this.fetched = [false, false, false];
  }
  componentDidMount() {
    const { hash } = this.props.location;
    const dict = Number(hash.slice(1, 2)) || 0;
    const { t } = this.props;
    const viewSet = t('usercenter', { returnObjects: true });
    setToken();
    this.setState({ dict });
    this.fetchData(dict);
    this.orderColumns = this.genOrderColumns(viewSet.order_info);
  }
  reqOrderList(page, size) {
    const handleError = (res) => this.alert(res.msg, 'danger');
    getOrderList({ page, size })
      .then((res) => {
        if (res.status === 0) {
          this.setState({
            order_list: {
              list: res.data.rows,
              total: res.data.total,
            }
          });
        } else {
          handleError(res);
        }
      }).catch(err => handleError(err.data));
  }
  fetchData(status) {
    if (this.fetched[status] === true) return;
    const handleError = (res) => this.alert(res.msg, 'danger');
    switch (status) {
      case 0:
        getUserInfo()
          .then(res => {
            if (res.status === 0) {
              this.setState({
                email: res.data.email,
                company: res.data.company,
                storeInfo: {
                  email: res.data.email,
                  company: res.data.company,
                },
              });
              this.fetched[status] = true
            } else {
              handleError(res);
            }
          }).catch(err => handleError(err.data));
        break;
      case 1:
        this.reqOrderList(1, 5);
        break;
      case 2:
        getKeyInfo()
          .then((res) => {
            if (res.status === 0) {
              this.setState({
                key_data: res.data
              });
              this.fetched[status] = true
            } else {
              handleError(res);
            }
          }).catch(err => handleError(err.data));
        break;
      default:
        break;
    }

  }

  changeState(name, content) {
    this.setState({
      [name]: content
    });
    if (name === 'dict') {
      this.fetchData(content);
    }
  }

  handleChange({ currentTarget: target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  alert(message, type) {
    this.setState(
      { alert: { type, message } },
      () => setTimeout(() => this.setState({ alert: '' }), 2000));
  }

  handleSubmit() {
    const { email, company, old_password, new_password, repeat_password } = this.state;
    const send = () => {
      const data = {};
      let modifyMail = false;
      ['email', 'company', 'old_password', 'new_password', 'repeat_password'].forEach(key => {
        if (this.state[key]) {
          data[key] = this.state[key];
        }
      });
      if (data.email && data.email !== this.state.storeInfo.email) {
        modifyMail = true;
      }
      if (Object.keys(data).length === 0) {
        this.alert('请输入要修改的内容', 'danger');
        return;
      }
      if (_.isEqual(data, this.state.storeInfo)) {
        this.alert('请填写你要修改的信息', 'warning');
        return;
      }
      this.setState({ loading: true });
      modifyUser(data).then((res) => {
        this.setState({ loading: false });
        if (res.status === 0) {
          if (modifyMail) {
            this.alert('一封验证邮件已发往您的新邮箱，请注意查收，及时验证。', 'success');
          } else {
            this.alert('已保存成功', 'success');
          }
          this.setState({ storeInfo: { email, company } });
        } else {
          this.alert(res.msg, 'danger');
        }
      }).catch(() => this.setState({ loading: false }));
    };

    if (old_password || new_password || repeat_password) {
      if (new_password !== repeat_password) {
        this.alert('两次输入的密码不一致', 'warning');
        return;
      }
      if (!(old_password && new_password && repeat_password)) {
        this.alert('请检查您是否已输入完整安全信息', 'danger');
        return;
      }
      send();
    } else {
      send();
    }
  }

  reset() {
    const { storeInfo } = this.state;
    this.setState({ email: storeInfo.email, company: storeInfo.company });
  }

  genOrderColumns(orderInfo) {
    const { t } = this.props;
    const columns = [
      {
        text: orderInfo.title[0],
        key: "order_no",
        thStyle: {
          width: '200px',
        },
      },
      {
        text: orderInfo.title[1],
        key: "total_price",
        thStyle: {
          width: '70px'
        },
      },
      {
        text: orderInfo.title[2],
        key: "create_time",
        thStyle: {
          width: '180px',
        }
      },
      {
        text: orderInfo.title[3],
        key: "pay_time",
        thStyle: {
          width: '150px',
        },
        render(time) {
          if (time === 0) {
            return orderInfo.order_status[4];
          }
          return dayjs.unix(time).format('YYYY-MM-DD hh:ss');
        }
      },
      {
        text: orderInfo.title[4],
        key: "product_list",
        thStyle: {
          width: '145px'
        },
        render(list) {
          return orderInfo.order_type[list[0].type];
        }
      },
      {
        text: orderInfo.title[5],
        key: 'status',
        thStyle: {
          width: '145px'
        },
        render(type, data) {
          const { pay_code, pay_type, product_list, create_time, id } = data; // 1是微信 2是支付宝
          const tN = Number(dayjs(create_time).format('YYYYMMDD'));
          const nN = Number(dayjs().format('YYYYMMDD'));
          // const now = new Date();
          // const nD = now.getDate(), nM = now.getMonth(), nY = now.getFullYear();
          // const nN = Number(`${nY}${nM}${nD}`);
          // const T = new Date(create_time);
          // const cD = T.getDate(), cM = T.getMonth(), cY = T.getFullYear();
          // const tN = Number(`${cY}${cM}${cD}`);
          console.log('对比时间', nN, tN);
          if (type === 1 && (nN - tN) > 0) {
            return <span className="status-3">{orderInfo.order_status[0]}</span>
          } else if (type === 1) {
            if (pay_type === 1) {
              return (
                <Link
                  className="pay-button shadow-sm text-center"
                  to={getLink('/price/order')}
                  state={{
                    payment: pay_type - 1,
                    qrCode: pay_code,
                    id: product_list.map(it => it.product_id),
                    orderId: id,
                  }}
                >
                  {orderInfo.order_status[type]}
                </Link>
              )
            } else if (pay_type === 2) {
              return <a className="pay-button shadow-sm text-center" href={pay_code} target="_blank">{orderInfo.order_status[type]}</a>
            }
          } else {
            return <span className={`status-${type}`}>{orderInfo.order_status[type]}</span>
          }
        }
      },
      {
        text: '',
        key: 'product_list',
        thStyle: {
          width: '290px'
        },
        render: (list) => {
          return (
            <React.Fragment>
              {list.map((info) => {
                let new_pro_id = product_id_map.get(parseInt(info.product_id))
                const type = ["DOTA2", "LOL", "CSGO", "AOV"][Math.floor((new_pro_id - 1) / 5)];
                const orderType = (new_pro_id - 1) % 5;
                const order = t('price.project.type_list', { returnObjects: true });

                return (
                  <p className="text-left">
                    {`${orderInfo.order_type[info.type]} ${type}-${order[orderType]}`}
                    <br />
                    {dayjs.unix(info.member_start_time).format('YYYY-MM-DD hh:ss')}
                    ~
                    {dayjs.unix(info.member_end_time).format('YYYY-MM-DD hh:ss')}
                  </p>)
              })}
            </React.Fragment>
          );
        }
      }
    ];
    return columns;
  }

  render() {
    const { t } = this.props;
    const { dict, alert, order_list, key_data, loading } = this.state;
    const viewSet = t('usercenter', { returnObjects: true });
    return (
      <Layout type="user_center">
        <SEO title="用户中心" />
        {alert && <Alert info={alert} />}
        <UHeader />
        <UContent>
          <ul className="title-header list-group text-center list-group-horizontal">
            {viewSet.info_list.map(
              (info, idx) => (
                <li
                  key={info}
                  className={`title-item ${dict === idx ? ' active' : ''}`}
                  onClick={() => this.changeState('dict', idx)}
                >{info}
                </li>
              )
            )}
          </ul>
          <div className={className("user-info u-container", { hide: dict !== 0, loading })}>
            {viewSet
              .user_info
              .map(info => (
                <React.Fragment key={info.title}>
                  <h3>{info.title}</h3>
                  {info.item.map(it => (
                    <div key={it.label} className="form-group">
                      <label className="text-right">{it.label}</label>
                      <input
                        type={it.type}
                        name={it.name}
                        placeholder={it.placeholder}
                        value={this.state[it.name]}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  ))}
                </React.Fragment>
              ))}
            <div className="info-button d-flex justify-content-center">
              <button
                className="web-button btn btn-primary"
                onClick={() => this.handleSubmit()}
              >{viewSet.save}</button>
              <button
                className="web-button btn btn-primary"
                onClick={() => this.reset()}
              >{viewSet.cancer}</button>
            </div>
          </div>
          <div className={className("order-info u-container", { hide: dict !== 1 })}>
            {
              order_list ? (
                <React.Fragment>
                  <Table cols={this.orderColumns} data={order_list.list} className="table shadow-sm" />
                  <Pagination
                    req={(page, size) => this.reqOrderList(page, size)}
                    total={order_list.total}
                    size={5}
                  />
                </React.Fragment>
              ) : <Spinner />
            }
          </div>
          <div className={className("key-info u-container", { hide: dict !== 2 })}>
            {
              key_data ? <KeyStatus alert={(...arg) => this.alert(...arg)} provideData={t('provideData', { returnObjects: true })} key_data={key_data} view_set={viewSet.key_status} /> : <Spinner />
            }
          </div>
          {loading && <LoadingWrapper />}
        </UContent>
      </Layout>
    )
  }
}

export default withI18next({ ns: 'common' })(UserCenter);